import { render, staticRenderFns } from "./PayType.vue?vue&type=template&id=7e6a9145&scoped=true&functional=true&"
var script = {}
import style0 from "./PayType.vue?vue&type=style&index=0&id=7e6a9145&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "7e6a9145",
  null
  
)

export default component.exports